<template>
  <div>
    <ManageBanners_1 />
  </div>
</template>
<script>
import ManageBanners_1 from "@/components/Banners_1/ManageBanners_1";
export default {
  components: {
    ManageBanners_1,
  },
  created() {
  },
};
</script>
